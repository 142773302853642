<script setup lang="ts">
import { computed, nextTick, ref, useSlots, watch } from "vue";
import AppFormInputError from "@/Components/Shared/forms/AppFormInputError.vue";
import AppFormFieldLabel from "@/Components/Shared/forms/AppFormFieldLabel.vue";
import AppFormField from "@/Components/Shared/forms/AppFormField.vue";
import { defaultFormEmits, defaultInputProps } from "@/Utils/form";
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import AppText from "@/Components/Shared/text/AppText.vue";
import AppIcon from "@/Components/Shared/icon/AppIcon.vue";
import AppLoader from "@/Components/Shared/loader/AppLoader.vue";

type InputType = "text" | "email" | "password" | "number" | "tel" | "url";
type InputSize = "small" | "regular";

type Props = {
    modelValue: string | number | null;
    type?: InputType;
    size?: InputSize;
    inputClass?: string;
    limit?: string | number | null;
} & defaultInputProps;

const {
    modelValue,
    type = "text",
    inputClass = undefined,
    size = "regular",
    limit = null,
    errors = [],
} = defineProps<Props>();

// setup
const input = ref(null);
const slots = useSlots();
const emit = defineEmits(defaultFormEmits);

const handleFocus = () => {
    if (input.value) {
        nextTick(() => {
            input.value.focus();
        });
    }
};

defineExpose({ input, handleFocus });

// classes
const classes: AntlerClasses<Props> = {
    base:
        "block w-full rounded-sm border-slate-300 shadow-sm focus:ring-primary/10 focus:ring-4 disabled:bg-gray-100 disabled:text-slate-700 duration-75 text-slate-700 leading-none placeholder:text-slate-500 " +
        inputClass,
    variants: {
        size: {
            small: "min-h-[30px] py-1 px-2",
            regular: "min-h-[40px]",
        },
    },
};
const { aClass } = installAntlerComponent("input-text", { size }, classes);

const hasReachedLimit = computed(() => {
    if (!limit) {
        return false;
    }

    if (modelValue && modelValue.length >= limit) {
        emit("invalid");

        return true;
    }

    return false;
});

// methods
const handleInput = (event: any) => {
    emit("update:modelValue", event.target.value);
};

const handleAnimation = (event: any) => {
    if (event.animationName == "onAutoFillStart") {
        emit("update:modelValue", event.target.value);
    }
};
</script>

<template>
    <AppFormField :has-icon="!!icon">
        <AppFormFieldLabel
            v-if="label"
            :for="name"
            :required="required"
            class="flex items-center gap-2"
        >
            {{ label }}

            <div
                v-if="limit"
                class="ml-auto"
            >
                <AppText
                    :key="`title-${hasReachedLimit}`"
                    :color="hasReachedLimit ? 'red' : 'gray'"
                    size="small"
                >
                    {{ modelValue ? modelValue.length : 0 }}/{{ limit }}
                    karakters
                </AppText>
            </div>
        </AppFormFieldLabel>

        <div class="flex relative grow">
            <div
                v-if="prefix"
                class="inline-flex items-center shrink-0 bg-slate-50 py-2 px-4 text-sm font-semibold text-slate-600 border border-slate-300 autofill:bg-yellow-50"
            >
                {{ prefix }}
            </div>
            <input
                :id="name"
                ref="input"
                :class="
                    aClass({
                        'pr-12': icon || slots.action,
                        'bg-slate-100 text-slate-500': readonly,
                        'border-red-500': errors && errors.length,
                    })
                "
                :placeholder="placeholder"
                :name="name"
                :type="type"
                :value="modelValue"
                :disabled="disabled"
                :readonly="readonly"
                :required="required"
                @focus="$emit('focus')"
                @input="handleInput"
                @animationstart="handleAnimation"
            />
            <AppLoader
                v-if="loading"
                class="absolute top-2.5 right-2 z-10"
                size="small"
            />
            <AppIcon
                v-else-if="icon"
                class="absolute top-2.5 right-4 z-10"
                :color="iconColor"
                :name="icon"
            />
            <slot name="action" />
            <slot name="after" />
        </div>

        <AppFormInputError
            v-if="errors && errors.length"
            :errors="errors"
        />
        <!-- eslint-disable vue/no-v-html -->
        <AppText
            v-else-if="hint"
            class="mt-1"
            size="small"
            color="grayLight"
            v-html="hint"
        />
    </AppFormField>
</template>
